import { createStore } from 'vuex'

export default createStore({
  state: {
   maxAya:"",
   minAya:"",
   selectedSurah:1,
   numberOfQuestions:5,
   questionsArray:[]
  },
  mutations: {
    SET_MAX_AYA(state,maxAya){
      state.maxAya = maxAya;
    },
    SET_MIN_AYA(state,minAya){
      state.minAya = minAya;
    },
    SET_SURAH(state,surah){
      state.selectedSurah = surah;
    },
    SET_NUMBER_OF_QUESTIONS(state,numberOfQuestions){
      if(numberOfQuestions <= 0) numberOfQuestions = 5;
      state.numberOfQuestions = numberOfQuestions;
    },
    SET_QUESTIONS_ARRAY(state,questionsArray){
      state.questionsArray = questionsArray;
    },
  },
  actions: {
    setMaxAya({commit},maxAya){
      commit('SET_MAX_AYA',maxAya);
    },
    setMinAya({commit},minAya){
      commit('SET_MIN_AYA',minAya);
    },
    setSurah({commit},surah){
      commit("SET_SURAH",surah);
    },
    setNumberOfQuestions({commit},numberOfQuestions){
      commit("SET_NUMBER_OF_QUESTIONS",numberOfQuestions);
    },
    setquestionsArray({commit},questionsArray){
      commit("SET_QUESTIONS_ARRAY",questionsArray);
    },
  },
  modules: {
  }
})
