var externalSurahList = [
    {name:"سورة الفاتحة",numberOfAya:7},
    {name:"سورة البقرة",numberOfAya:286},
    {name:"سورة آل عمران",numberOfAya:200},
    {name:"سورة النساء",numberOfAya:176},
    {name:"سورة المائدة",numberOfAya:120},
    {name:"سورة الأنعام",numberOfAya:165},
    {name:"سورة الأعراف",numberOfAya:206},
    {name:"سورة الأنفال",numberOfAya:75},
    {name:"سورة التوبة",numberOfAya:129},
    {name:"سورة يونس",numberOfAya:109},
    {name:"سورة هود",numberOfAya:123},
    {name:"سورة يوسف",numberOfAya:111},
    {name:"سورة الرعد",numberOfAya:43},
    {name:"سورة ابراهيم",numberOfAya:52},
    {name:"سورة الحجر",numberOfAya:99},
    {name:"سورة النحل",numberOfAya:128},
    {name:"سورة الإسراء",numberOfAya:111},
    {name:"سورة الكهف",numberOfAya:110},
    {name:"سورة مريم",numberOfAya:98},
    {name:"سورة طه",numberOfAya:135},
    {name:"سورة الأنبياء",numberOfAya:112},
    {name:"سورة الحج",numberOfAya:78},
    {name:"سورة المؤمنون",numberOfAya:118},
    {name:"سورة النور",numberOfAya:64},
    {name:"سورة الفرقان",numberOfAya:77},
    {name:"سورة الشعراء",numberOfAya:227},
    {name:"سورة النمل",numberOfAya:93},
    {name:"سورة القصص",numberOfAya:88},
    {name:"سورة العنكبوت",numberOfAya:69},
    {name:"سورة الروم",numberOfAya:60},
    {name:"سورة لقمان",numberOfAya:34},
    {name:"سورة السجدة",numberOfAya:30},
    {name:"سورة الأحزاب",numberOfAya:73},
    {name:"سورة سَبَأ",numberOfAya:54},
    {name:"سورة فاطر",numberOfAya:45},
    {name:"سورة يس",numberOfAya:83},
    {name:"سورة الصافات",numberOfAya:182},
    {name:"سورة ص",numberOfAya:88},
    {name:"سورة الزمر",numberOfAya:75},
    {name:"سورة غافر",numberOfAya:85},
    {name:"سورة فصلت",numberOfAya:54},
    {name:"سورة الشورى",numberOfAya:53},
    {name:"سورة الزخرف",numberOfAya:89},
    {name:"سورة الدخان",numberOfAya:59},
    {name:"سورة الجاثية",numberOfAya:37},
    {name:"سورة الأحقاف",numberOfAya:35},
    {name:"سورة محمد",numberOfAya:38},
    {name:"سورة الفتح",numberOfAya:29},
    {name:"سورة الحجرات",numberOfAya:18},
    {name:"سورة ق",numberOfAya:45},
    {name:"سورة الذاريات",numberOfAya:60},
    {name:"سورة الطور",numberOfAya:49},
    {name:"سورة النجم",numberOfAya:62},
    {name:"سورة القمر",numberOfAya:55},
    {name:"سورة الرحمن",numberOfAya:78},
    {name:"سورة الواقعة",numberOfAya:96},
    {name:"سورة الحديد",numberOfAya:29},
    {name:"سورة المجادلة",numberOfAya:22},
    {name:"سورة الحشر",numberOfAya:24},
    {name:"سورة الممتحنة",numberOfAya:13},
    {name:"سورة الصف",numberOfAya:14},
    {name:"سورة الجمعة",numberOfAya:11},
    {name:"سورة المنافقون",numberOfAya:11},
    {name:"سورة التغابن",numberOfAya:18},
    {name:"سورة الطلاق",numberOfAya:12},
    {name:"سورة التحريم",numberOfAya:12},
    {name:"سورة الملك",numberOfAya:30},
    {name:"سورة القلم",numberOfAya:52},
    {name:"سورة الحاقة",numberOfAya:52},
    {name:"سورة المعارج",numberOfAya:44},
    {name:"سورة نوح",numberOfAya:28},
    {name:"سورة الجن",numberOfAya:28},
    {name:"سورة المزمل",numberOfAya:20},
    {name:"سورة المدثر",numberOfAya:56},
    {name:"سورة القيامة",numberOfAya:40},
    {name:"سورة الانسان",numberOfAya:31},
    {name:"سورة المرسلات",numberOfAya:50},
    {name:"سورة النبأ",numberOfAya:40},
    {name:"سورة النازعات",numberOfAya:46},
    {name:"سورة عبس",numberOfAya:42},
    {name:"سورة التكوير",numberOfAya:29},
    {name:"سورة الإنفطار",numberOfAya:19},
    {name:"سورة المطففين",numberOfAya:36},
    {name:"سورة الإنشقاق",numberOfAya:25},
    {name:"سورة البروج",numberOfAya:22},
    {name:"سورة الطارق",numberOfAya:17},
    {name:"سورة الأعلى",numberOfAya:19},
    {name:"سورة الغاشية",numberOfAya:26},
    {name:"سورة الفجر",numberOfAya:30},
    {name:"سورة البلد",numberOfAya:20},
    {name:"سورة الشمس",numberOfAya:15},
    {name:"سورة الليل",numberOfAya:21},
    {name:"سورة الضحى",numberOfAya:11},
    {name:"سورة الشرح",numberOfAya:8},
    {name:"سورة التين",numberOfAya:8},
    {name:"سورة العلق",numberOfAya:19},
    {name:"سورة القدر",numberOfAya:5},
    {name:"سورة البينة",numberOfAya:8},
    {name:"سورة الزلزلة",numberOfAya:8},
    {name:"سورة العاديات",numberOfAya:11},
    {name:"سورة القارعة",numberOfAya:11},
    {name:"سورة التكاثر",numberOfAya:8},
    {name:"سورة العصر",numberOfAya:3},
    {name:"سورة الهمزة",numberOfAya:9},
    {name:"سورة الفيل",numberOfAya:5},
    {name:"سورة قريش",numberOfAya:4},
    {name:"سورة الماعون",numberOfAya:7},
    {name:"سورة الكوثر",numberOfAya:3},
    {name:"سورة الكافرون",numberOfAya:6},
    {name:"سورة النصر",numberOfAya:3},
    {name:"سورة المسد",numberOfAya:5},
    {name:"سورة الإخلاص",numberOfAya:4},
    {name:"سورة الفلق",numberOfAya:5},
    {name:"سورة الناس",numberOfAya:6}
    
    ]
export default externalSurahList;