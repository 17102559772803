<template>
  <div class="ayaSelect">
    <select class="surahSelect" v-model="surahIndex" @change="surahChanged">
      <option v-for="(surah, index) in surrahList" :key="index" :value="index + 1">{{ surah.name }}</option>
    </select>

    <label class="ayaSelectLabel">
      <p>
        اختبر حفظك من
        <span class="ayaName">{{ ayaName }}</span> بين الآيات
        التالية:
      </p>
    </label>
    <div class="input-grid">
      <div class="input-grid-min-aya">
        <input class="input input-grid-item" @change="minChanged" v-model.number="minAya" type="number" placeholder="الآية الاولى" />
      </div>
      <div class="input-grid-max-aya">
        <input class="input input-grid-item" @change="maxChanged" v-model.number="maxAya" type="number" placeholder="الآية الأخيرة" />
      </div>
      <button-component @click="getFullSurahAyaNumbers" background-color="#2176ff" class="input-grid-item input-grid-button">السورة كاملة</button-component>
    </div>
   
      
      <div class="select-questions-input-container">
        <p class="select-questions-label">اختر عدد الاسئلة:</p>
        <!-- <input class="select-questions-input" type="number" @click="clearQuestions($event)" @blur="checkIfQuestionIsNull($event)" v-model.number="questions"> -->
      </div>
      <questions-picker @questions-changed="changeQuestions($event)"></questions-picker>
   
  </div>
</template>

<script>
import externalSurahList from "../assets/surahList.js";
import store from "../store";
import ButtonComponent from "../components/ButtonComponent";
import QuestionsPicker from "../components/QuestionsPicker";
//import axios from "axios";
export default {
  name: "InputComponent",
  components: { ButtonComponent, QuestionsPicker },
  emits:["surah-changed"],
  data() {
    return {
      surrahList: externalSurahList,
      surahIndex: 1,
      minAya: null,
      maxAya: null,
      questions: 5,
    };
  },
  computed: {
  
    ayaName() {
      return this.surrahList[this.surahIndex - 1].name;
    },
    
  },
  watch:{
    questions: function(newNumber){
      store.dispatch("setNumberOfQuestions",newNumber);
      //console.log(store.state.numberOfQuestions);
    },
    surahIndex: function(){
      this.maxAya = null;
      this.minAya = null;
      store.state.maxAya = null;
      store.state.minAya = null;
      this.$emit("surah-changed");
      
    }
  },
  methods: {
    minChanged() {
      store.dispatch("setMinAya", this.minAya);
    },
    maxChanged() {
      store.dispatch("setMaxAya", this.maxAya);
    },
    surahChanged() {
      store.dispatch("setSurah", this.surahIndex);
    },
    getFullSurahAyaNumbers() {
      this.minAya = 1;
      this.maxAya = this.surrahList[this.surahIndex - 1].numberOfAya;
      store.dispatch("setMinAya", this.minAya);
      store.dispatch("setMaxAya", this.maxAya);
    },
     changeQuestions(newQuestionNumber) {
       this.questions = newQuestionNumber;
      store.dispatch("setNumberOfQuestions",this.questions);
     },
   
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.input-grid {
  display: grid;
  //  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  //  grid-template-rows: repeat(2, 1fr);
  //grid-template-columns: fit-content;
  grid-template:
    "btn input1 "
    "btn input2 ";
  grid-template-columns: 100px auto;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  input {
    width: 100%;
  }
  .input-grid-button {
    // min-width: 70px;

    font-size: 15px;
    height: 90%;
    margin: 10px 0 10px 0;
    padding: 0 0 0 0;

    grid-area: btn;
  }
  .input-grid-max-aya {
    grid-area: input2;
    padding-left: 10px;
    @media (max-width: 343px) {
      //max-width: 100px;
    }
  }
  .input-grid-min-aya {
    grid-area: input1;
    padding-left: 10px;
    @media (max-width: 343px) {
      // margin-left:100px;
    }
  }
}

.ayaSelect {
  .ayaSelectLabel {
    p {
      margin: 0 0 0 0;
      font-size: 20px;
    }

    .ayaName {
      color: #fdca40;
    }
  }
  display: flex;
  flex-direction: column;
  * {
    direction: rtl;
  }
  .surahSelect {
    font-family: "Almarai", sans-serif;
    height: 50px;
    padding: 5px;
    font-size: 20px;
  }

  input,
  select {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: none;
    color: white;
    background: #183a37;
  }
  label {
    color: white;
    font-family: "Tajawal", sans-serif;
    font-size: 20px;
    margin-top: 20px;
  }
  .input {
    font-family: "Almarai", sans-serif;

    height: 30px;
    margin-top: 10px;
    padding: 5px;
    font-size: 20px;
  }
}
.select-questions-input-container {
  padding-left: 10px;
  .select-questions-label{
    color: white;
    font-family: "Tajawal", sans-serif;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom:0px;
  }
  .select-questions-input {
    font-family: "Almarai", sans-serif;
    outline: none;
    height: 30px;
    margin-top: 10px;
    padding: 5px;
    font-size: 20px;
    text-align: center;
    width: 100%;
    -webkit-border-radius: 5px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
