<template>
  <div id="font-size-nav">

    <button @click="changeNumberOfQuestions(3)" class="font-size-nav-item left-item" :class="{active:numberOfQuestions ==3}">3</button>
    <button @click="changeNumberOfQuestions(5)" class="font-size-nav-item" :class="{active:numberOfQuestions ==5}">5</button>
    <button @click="changeNumberOfQuestions(10)" class="font-size-nav-item " :class="{active:numberOfQuestions ==10}">10</button>
    <button @click="changeNumberOfQuestions(15)" class="font-size-nav-item " :class="{active:numberOfQuestions ==15}">15</button>
    <button @click="changeNumberOfQuestions(20)" class="font-size-nav-item right-item" :class="{active:numberOfQuestions ==20}">20</button>
   
  </div>

</template>

<script>
export default {
  name: "FontSize",
  emits: ["questions-changed"],
  data() {
    return {
      numberOfQuestions: 5,
    };
  },
  methods: {
    changeNumberOfQuestions(numberOfQuestions) {
      this.numberOfQuestions = numberOfQuestions;
      this.$emit("questions-changed", numberOfQuestions);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#font-size-nav {
  align-items: center;

  justify-content: space-between;
  margin-top:5px;
  display: flex;
  width: 100%;
  max-width: 800px;

  margin-left: auto;
  margin-right: auto;
  .right-item {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .left-item {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .font-size-nav-item {
    font-family: "Almarai", sans-serif;
    font-size: 18px;
    height: 45px;
    outline: none;
    flex-direction: row;
    color: white;
    background-color: #0e2220;
    border: none;
    text-decoration: none;
    border: 1px solid #0e2220;

    flex-grow: 1;
  }
  .active{
    background-color:white;
    color:black;
  }
}
</style>
