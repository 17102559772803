<template>
  <div class="container">
    <img alt="logo" src="./assets/logo.png" />

    <div v-if="errorGotten" class="result gottenError">
      <p>
        الرجاء التأكد من أرقام الآيات ومن السورة
      </p>
    </div>
    <div v-if="testComplete" class="result testComplete">
      <p>
        انتهى الاختبار، جزاك الله خيرا
      </p>
    </div>
    <div v-if="showTimer" class="countDown">
      <p class="countdownLabel">قم بالتسميع الى انتهاء الوقت:</p>
      <p class="countdownTimer">{{ timer }}</p>
    </div>

    <div class="top-button-container">
      <div class="result testComplete" @click="getNextAya" v-if="timerDone">
        <p>انتهى الوقت</p>
        <p>الرجاء الإنتقال للسؤال التالي</p>
      </div>
    </div>
    <div v-if="ayaGotten" class="result gottenAya">
      <p class="ayaNumber">السؤال رقم : {{questionOrder }} من {{numberOfQuestions}} <span class="aya-seperator">|</span> رقم الآية : {{ ayaNumber }} <span class="aya-seperator">|</span> الصفحة : {{page}}</p>
      <hr />
      <p class="aya-full-text" v-html="ayaText"> </p>
      <div class="ayaGuide" v-if="!isFullAya">
        <p class="fullPage" @click="showFullPage">
          اظهر الصفحة كاملة
        </p>
        <p class="fullAya" @click="showFullAya">
          اظهر تكملة الآية
        </p>
       

      </div>
      <div class="ayaGuide" v-if="isFullAya ">
       
       
<p class="fullPage" @click="getPreviousPage">
          الصفحة السابقة
        </p>
         <p class="fullAya" @click="getNextPage">
          الصفحة التالية
        </p>
      </div>
    </div>
    <div v-if="ayaGotten" class="ayaSelect">
      <button-component @click="getNextAya" class="get-next-aya-button">السؤال التالي</button-component>
    </div>
    <input-component @surah-changed="surahChanged"></input-component>
    <div class="btn-container">
      <button-component text-color="white" background-color="#c44900" @click="getAyaNoTimer">اختبار جديد بدون توقيت</button-component>
      <button-component text-color="white" background-color=" #432534" @click="getAyaWithTimer"><img src="./assets/timer.png" />
        <span>اختبار جديد مع التوقيت</span>
      </button-component>
    </div>
    <!-- <InputComponent /> -->
    <div class="footer">
      <p>برنامج يساعد على اختبار مدى حفظك لكتاب الله عز وجل</p>

      <p>وقف لله</p>
    </div>
  </div>
</template>

<script>
//import InputComponent from './components/InputComponent.vue';
import ButtonComponent from "./components/ButtonComponent.vue";
import externalSurahList from "./assets/surahList";
import axios from "axios";
import store from "./store";
import InputComponent from "./components/InputComponent.vue";
export default {
  name: "App",
  components: {
    ButtonComponent,
    InputComponent,
  },
  data() {
    return {
      isFullAya: false,
      surrahList: externalSurahList,
      lastAya: 1,
      ayaText: "",
      fullAyaText: "",
      ayaGotten: false,
      ayaNumber: 1,
      errorGotten: false,
      timerDone: false,
      timer: "",
      timerStarted: false,
      intervalId: 0,
      ayaTimer: 120,
      questionsArray: [],
      questionOrder: 0,
      numberOfQuestions: 0,
      showTimer: false,
      isWithTimer: false,
      page: 0,
    };
  },
  computed: {
    ayaName() {
      return this.surrahList[this.surahIndex - 1];
    },
    surahIndex() {
      return store.state.selectedSurah;
    },
    minAya() {
      return store.state.minAya;
    },
    maxAya() {
      return store.state.maxAya;
    },
  },

  methods: {
    getAyaNoTimer() {
      clearInterval(this.intervalId);
      this.iswithTimer = false;
      this.questionOrder = 0;
      this.isFullAya = false;
      this.testComplete = false;
      this.timerDone = false;
      this.showTimer = false;
      this.numberOfQuestions = store.state.numberOfQuestions;
      //console.log(store.state.numberOfQuestions);
      clearInterval(this.intervalId);
      this.getQuestionsArray();
      //this.getAyaWithAxios("noTimer");
      this.getAyaWithAxios(
        store.state.selectedSurah,
        this.questionsArray[this.questionOrder]
      )
        .then((response) => {
          this.ayaNumber = this.questionsArray[this.questionOrder];
          this.setAyasText(response.firstAya, response.secondAya);
          this.questionOrder += 1;
          this.ayaGotten = true;
          this.errorGotten = false;
          this.showTimer = false;
        })
        .catch(() => {
          this.ayaGotten = false;
          this.showTimer = false;
          this.errorGotten = true;
        });
    },
    getAyaWithTimer() {
      this.questionOrder = 0;
      this.isWithTimer = true;

      this.isFullAya = false;
      this.testComplete = false;
      this.timerDone = false;
      //this.showTimer = true;
      this.numberOfQuestions = store.state.numberOfQuestions;
      //console.log(store.state.numberOfQuestions);
      // clearInterval(this.intervalId);
      this.startTimer(this.ayaTimer);
      this.getQuestionsArray();
      //this.getAyaWithAxios("noTimer");
      this.getAyaWithAxios(
        store.state.selectedSurah,
        this.questionsArray[this.questionOrder]
      )
        .then((response) => {
          this.ayaNumber = this.questionsArray[this.questionOrder];
          this.setAyasText(response.firstAya, response.secondAya);
          this.questionOrder += 1;
          this.ayaGotten = true;
          this.errorGotten = false;
          this.showTimer = true;
          this.timerStarted = true;
        })
        .catch(() => {
          this.ayaGotten = false;
          this.showTimer = false;
          this.errorGotten = true;
        });
    },
    getNextAya() {
      clearInterval(this.intervalId);

      if (this.isWithTimer) this.startTimer(this.ayaTimer);

      this.getAyaWithAxios(
        store.state.selectedSurah,
        this.questionsArray[this.questionOrder]
      )
        .then((response) => {
          if (this.isWithTimer) this.showTimer = true;
          this.ayaNumber = this.questionsArray[this.questionOrder];
          this.setAyasText(response.firstAya, response.secondAya);
          this.questionOrder += 1;
          this.isFullAya = false;
        })
        .catch(() => {
          clearInterval(this.intervalId);
          this.ayaGotten = false;
          this.showTimer = false;
          this.errorGotten = false;
          this.testComplete = true;
          this.questionOrder = 0;
        });
    },
    getNextPage(){
      this.page++;
      this.showFullPage();

      
    },
    getPreviousPage(){
      this.page--;
      this.showFullPage();

      
    },
    showFullAya() {
      this.ayaText = this.fullAyaText;
      this.isFullAya = true;
    },
    startTimer(duration) {
      this.timerDone = false;

      if (this.timerStarted == true) {
        clearInterval(this.intervalId);
        this.timerStarted = false;
        this.startTimer(duration);
      } else {
        this.timerStarted = true;
        var timer = duration,
          minutes,
          seconds;
        const that = this;
        this.intervalId = setInterval(function () {
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          that.timer = minutes + ":" + seconds;

          if (--timer < 0) {
            clearInterval(that.intervalId);
            that.showTimer = false;
            that.timerDone = true;
            timer = duration;
          }
        }, 1000);
      }
    },

    getAyaWithAxios(surahToGet, ayaToGet) {
      const that = this;
      var firstQuery = surahToGet + ":" + ayaToGet;
      var secondAyaToGet = ayaToGet + 1;
      var secondQuery = surahToGet + ":" + secondAyaToGet;
      var firstAyaText = "";
      var secondAyaText = "";
      return new Promise((resolve, reject) => {
        axios
          .get("https://api.alquran.cloud/v1/ayah/" + firstQuery)
          .then((response) => {
            console.log(response.data.data.page);
            that.page = response.data.data.page;
            firstAyaText = response.data.data.text;
            axios
              .get("https://api.alquran.cloud/v1/ayah/" + secondQuery)
              .then((secondResponse) => {
                secondAyaText = secondResponse.data.data.text;
                resolve({ firstAya: firstAyaText, secondAya: secondAyaText });
              })
              .catch(() => {
                reject(false);
              });
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    showFullPage() {
      var myAya = "";
      this.getPageWithAxios().then((response) => {
        console.log(response);
        response.ayahs.forEach((aya) => {
          myAya += aya.text;
          myAya +=
            "<span class='aya-seperator'> (</span><span class='aya-number'>" +
            aya.numberInSurah +
            "</span><span class='aya-seperator'>) </span>";
        });
        this.ayaText = myAya;

        this.isFullAya = true;
      });
    },
    getPageWithAxios() {
      return new Promise((resolve, reject) => {
        axios
          .get("https://api.alquran.cloud/v1/page/" + this.page + "")
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getQuestionsArray() {
      var numberOfQuestions = store.state.numberOfQuestions;
      console.log(numberOfQuestions);
      var questionsArray = [];
      questionsArray.length = 0;
      var quarters = [];
      quarters.length = 0;

      var min = this.minAya;
      var max = this.maxAya;
      console.log("Min,Max", min, max);
      var distance = max - min;
      console.log("Distnace:", distance);
      var quarter = Math.floor(distance / numberOfQuestions);
      var currentQuarter = 0;
      for (var i = 1; i < numberOfQuestions; i++) {
        currentQuarter = min + quarter;
        quarters.push(currentQuarter);
        questionsArray.push(getRand(min, currentQuarter));
        min = currentQuarter;
      }
      currentQuarter = min + quarter;
      questionsArray.push(getRand(min, currentQuarter));
      console.log("Quarters:", quarters);
      console.log("Ayas:", questionsArray);
      this.questionsArray = questionsArray;
    },
    setAyasText(firstAyaText, secondAyaText) {
      this.ayaText = cutString(firstAyaText) + "...";
      this.fullAyaText =
        firstAyaText +
        "<span class='aya-seperator'> (</span><span class='aya-number'>" +
        this.ayaNumber +
        "</span><span class='aya-seperator'>) </span>" +
        secondAyaText +
        "<span class='aya-seperator'> (</span><span class='aya-number'>" +
        (this.ayaNumber + 1) +
        "</span><span class='aya-seperator'>) </span>";
      console.log(this.fullAyaText);
    },
    surahChanged() {
      this.questionOrder = 0;
      this.questionsArray.length = 0;
      this.numberOfQuestions = 5;
      store.state.numberOfQuestions = 5;
      store.state.questionsArray.length = 0;
      this.ayaGotten = false;
      clearInterval(this.intervalId);
      this.errorGotten = false;
      this.timerStarted = false;
      this.showTimer = false;
    },
  },
  mounted() {},
};
function getRand(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
function cutString(yourString) {
  var maxLength = 100; // maximum number of characters to extract

  //trim the string to the maximum length
  var trimmedString = yourString.substr(0, maxLength);

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmedString;
}
</script>

<style lang="scss">
body {
  background: #04151f;
  text-align: center;
}
.aya-seperator {
  color: #c44900;
}
.aya-number {
  color: #106932;
  font-size: 15px;
}
.footer {
  direction: rtl;
  color: white;
  font-family: "Almarai", sans-serif;
  font-size: 14px;
  margin-top: 50px;
}
.container {
  img {
    width: 100%;
    margin-bottom: 0;
  }
  margin: 0 auto 0 auto;
  max-width: 600px;
}

.top-button-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}
.ayaSelectLabel {
  p {
    margin: 0 0 0 0;
    font-size: 20px;
  }

  .ayaName {
    color: #c44900;
  }
}

.btn-container {
  img {
    width: 30px;
    margin: 0 0 0 0;
    vertical-align: middle;
  }
  display: flex;
  flex-direction: column;
  * {
    direction: rtl;
  }
}
.ayaSelect {
  display: flex;
  flex-direction: column;
  * {
    direction: rtl;
  }
  .surahSelect {
    font-family: "Almarai", sans-serif;
    height: 50px;
    padding: 5px;
    font-size: 20px;
  }

  input,
  select {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: none;
    color: white;
    background: #183a37;
  }
  label {
    color: white;
    font-family: "Tajawal", sans-serif;
    font-size: 20px;
    margin-top: 20px;
  }
  .input {
    font-family: "Almarai", sans-serif;

    height: 30px;
    margin-top: 10px;
    padding: 5px;
    font-size: 20px;
  }
  .get-next-aya-button {
    margin-top: 0px;
    margin-bottom: 20px;
    -webkit-border-radius: 0px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius: 0px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;
  }
}
.result {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  margin-bottom: 0px;
}

.gottenError,
.gottenAya,
.testComplete {
  direction: rtl;
  border: none;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;

  p {
    text-align: justify;
  }
}

.gottenAya {
  hr {
    margin: 0 0 5px 0;
    border: 1px solid #c44900;
  }
  p {
    text-align: justify;
    text-justify: inter-word;
    margin: 0 0 0 0;
  }
  .ayaNumber {
    font-size: 18px;
  }
  background-color: #efd6ac;
  color: #04151f;
  font-family: "Kitab-Regular";
  font-size: 25px;
  font-weight: 700;
  .ayaGuide {
    text-align: center;

    padding: 5px;
    display: flex;
    flex-direction: row;

    font-family: "Tajawal", sans-serif;

    color: #d62828;
    gap: 10px;
    p {
      width: 100%;
      padding: 5px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 15px;
      background: white;
      text-align: center;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
    .fullPage {
      background-color: black;
      color: white;
    }
  }
}
.gottenError {
  background-color: #d62828;
  color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.testComplete {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #003049;
  font-family: "Almarai", sans-serif;
  color: white;
  padding: 10px;
  direction: rtl;
  margin-bottom: 10px;

  p {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    margin-top: 10px;
    margin-bottom: 6px;
  }
}
.countDown {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #003049;
  font-family: "Almarai", sans-serif;
  color: white;
  padding: 10px;
  direction: rtl;
  margin-bottom: 10px;
  p {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    margin-top: 10px;
  }
  .countdownTimer {
    color: #f77f00;
    font-size: 30px;
  }
}
</style>
