<template>
  <button  class="btn" :style="{backgroundColor:backgroundColor,color:textColor}" >
      <slot> </slot>
      </button>
</template>

<script>
  export default {
    name: "ButtonComponent",
    props:['backgroundColor',"textColor",'img'],
    
  };
</script>

<style lang="scss" scoped>

.btn {
      height: 50px;

      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      border: none;
      color: white;
      background: #c44900;
      font-family: "Tajawal", sans-serif;
      font-size: 20px;
      margin-top: 20px;

      img {
        width: 30px;
        margin: 0 0 0 0;
        vertical-align: middle;
      }
    }</style>
